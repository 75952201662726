.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center; 
    align-items: center;   
    z-index: 9999; 
}

.custom-loader {
    width: 100px;
    height: 100px;
    display: grid;
    border:8px solid #0000;
    border-radius: 50%;
    border-color:#141414 #0000;
    animation: s6 0.5s infinite linear;
  }
  .custom-loader::before,
  .custom-loader::after {    
    content:"";
    grid-area: 1/1;
    margin:4px;
    border:inherit;
    border-radius: 50%;
  }
  .custom-loader::before {
    border-color:#00fff6 #0000;
    animation:inherit; 
    animation-duration: 0.25s;
    animation-direction: reverse;
  }
  .custom-loader::after {
    margin:16px;
  }
  
  @keyframes s6 { 
    100%{transform: rotate(1turn)}
  }